@import 'styles/includes';

.HeroArticlePage {
    $root: &;
    background-color: $black;

    &__Container {
        @extend %container;
        padding-top: 14rem;
        padding-bottom: 4rem;

        @include media(m) {
            padding-top: 27.4rem;
            padding-bottom: 8rem;
        }
    }

    &__Title {
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -1.44px;
        color: $white;

        @include media(m) {
            font-size: 4.8rem;
        }
    }

    &__Image {
        position: relative;
        aspect-ratio: 144/72;
    }

    &__Breadcrumbs {
        margin: 0 0 0.5rem;
        font-family: $font-family-regular;
        font-size: 1.4rem;
        line-height: normal;
        color: $grey-60;

        @include media(l) {
            font-size: 2rem;
        }
    }

    &__PageLink {
        &::after {
            content: ' / ';
        }
    }

    &__CurrentPage {
        color: $white;
    }
}
