@import 'styles/includes';

.CtaWithImage {
    $root: &;

    padding: 12rem 0;
    overflow: hidden;

    &__Container {
        @extend %container;

        @include media(l) {
            display: flex;
            gap: 6rem;
            align-items: center;
        }

        @include media(xl) {
            gap: 16.5rem;
        }
    }

    &__Image {
        width: 100%;

        position: relative;
        aspect-ratio: 705/740;
        position: relative;
        background-color: $black;

        @include media(l) {
            width: 57.32%;
            flex-shrink: 0;
        }

        #{$root}--Reverse & {
            @include media(l) {
                order: 1;
            }
        }
    }

    &__Content {
        padding: 4rem 0;
        background-color: $grey-20;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include media(l) {
            padding: 6rem 0;
        }

        &::before {
            content: '';
            width: 1000vw;
            left: -100vw;
            position: absolute;
            background-color: $grey-20;
            z-index: -1;
            top: 0;
            height: 100%;
        }

        #{$root}--Reverse & {
            @include media(l) {
                order: 0;
            }
        }
    }

    &__Title {
        font-family: $font-family-bold;
        font-size: 4rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.03rem;
        line-height: 100%;

        @include media(s) {
            font-size: 5rem;
        }

        @include media(m) {
            font-size: 4.8rem;
        }

        @include media(l) {
            font-size: 5rem;
        }

        @include media(xl) {
            font-size: 6.2rem;
        }
    }

    &__Text {
        margin: 3rem 0;
        font-family: $font-family-regular;
        font-size: 1.6rem;
        font-weight: $font-weight-regular;
        line-height: 100%;

        @include media(l) {
            font-size: 1.8rem;
        }
    }
}
