@import 'styles/includes';

.ImageLink {
    $root: &;

    background-color: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: background-color $transition-ease-out-expo;

    @media (hover: hover) {
        &:hover {
            background-color: $white;
            box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 1);
        }
    }

    &__Link {
        @extend %card-link;
        transition: box-shadow $transition-ease-out-expo;

        @media (hover: hover) {
            #{$root}:hover & {
                box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 1);
            }
        }
    }

    &__Title {
        padding: 2rem;
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        transition: color $transition-ease-out-expo;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $black;
            }
        }

        @include media(s) {
            font-size: 4.6rem;
        }

        @include media(m) {
            font-size: 2.2rem;
        }

        @include media(l) {
            font-size: 3rem;
        }

        @include media(xl) {
            font-size: 3.6rem;
        }
    }

    &__Image {
        position: relative;
        aspect-ratio: 30/44;
    }
}
