@import 'styles/includes';

.FiftyFiftyCta {
    $root: &;

    width: 100%;
    border: 1rem solid $white;
    position: relative;

    &__Link {
        @extend %card-link;
    }

    &__ImageContainer {
        aspect-ratio: 605/705;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1rem solid $white;
    }

    &__Image {
        position: absolute;
        inset: 0;
    }

    &__Title {
        font-family: $font-family-bold;
        font-size: 4rem;
        font-weight: $font-weight-bold;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0rem;
        color: $white;
        position: relative;
        z-index: 1;

        @include media(s) {
            font-size: 8rem;
            letter-spacing: -0.3rem;
        }
    }

    &__Button {
        padding: 2rem;
        background-color: $black;
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        color: $white;
        text-align: center;
        text-transform: uppercase;
        display: block;
        position: relative;
        z-index: 0;
        transition: color $transition-ease-out-expo;

        &::after {
            content: '';
            width: 0;
            background-color: $violet;
            inset: 0;
            position: absolute;
            z-index: -1;
            transition: width $transition-ease-out-expo;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                color: $black;

                &::after {
                    width: 100%;
                }
            }
        }
    }

    &__HiddenTitle {
        visibility: hidden;
        line-height: 100%;
    }
}
