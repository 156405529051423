@import 'styles/includes';

.Image {
    $root: &;

    &--Static {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
    }
}
