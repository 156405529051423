@import 'styles/includes';

.NewsHub {
    $root: &;
    @extend %container;
    margin: 0 auto 6rem;

    @include media(m) {
        margin: 0 auto 10rem;
        padding-top: 6rem;
    }

    @include media-max(l) {
        padding: 0;
    }

    &__Highlight {
        margin-bottom: 2rem;

        @include media(m) {
            margin-bottom: 4rem;
        }
    }

    &__Filter {
        @extend %container;
        margin-bottom: 2rem;

        @include media(m) {
            margin-bottom: 4rem;
        }

        @include media(l) {
            padding: 0;
        }
    }

    &__Container {
        margin-bottom: 2rem;
    }

    &__Grid {
        display: grid;

        @include media(l) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
