@import 'styles/includes';

.NewsList {
    $root: &;
    @extend %container;
    margin-top: 6rem;
    margin-bottom: 6rem;
    overflow: hidden;

    @include media(m) {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }

    &__Header {
        margin: 0 0 3rem;

        @include media(s) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__Title {
        margin: 0 0 1.4rem;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 3rem;
        line-height: 100%;
        color: $black;
        text-align: center;

        @include media(s) {
            margin: 0;
            font-size: 4.5rem;
            text-align: left;
        }
    }

    &__List {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
    }

    &__Link {
        font-family: $font-family-semibold;
        font-size: 1.4rem;
        font-weight: $font-weight-medium;
        line-height: 100%;
        text-decoration: underline;
        text-decoration-thickness: 0.1rem;
        text-underline-offset: 0.3rem;
        text-decoration-color: $black;
        color: $black;
        transition: text-decoration-color $transition-linear;
        display: inline-block;

        @include media(s) {
            font-size: 1.6rem;
        }

        @media (hover: hover) {
            &:hover {
                text-decoration-color: transparent;
            }
        }
    }

    &__Grid {
        display: grid;
        position: relative;
        overflow: visible !important;

        @include media(m) {
            margin: 0;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            border-right: 0.1rem solid $black;
            border-top: 0.1rem solid $black;
            border-bottom: 0.1rem solid $black;
        }

        @include media(l) {
            border-left: 0.1rem solid $black;
        }

        &::before,
        &::after {
            content: '';
            width: calc(100% + 40px);
            height: 1px;
            background-color: $black;
            position: absolute;
            left: -20px;
            right: -20px;

            @include media(s) {
                width: calc(100% + 60px);
                left: -30px;
                right: -30px;
            }

            @include media(m) {
                display: none;
            }
        }

        &::after {
            top: 100%;
        }
    }

    &__Card {
        min-height: 44rem;
        height: auto !important;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;
        border-right: 1px solid $black;

        @include media(m) {
            box-shadow: -0.1rem 0 0 0 $black;
            border-right: 0;
        }
    }

    &__Pagination {
        display: flex;
        gap: 1rem;
    }

    &__Bullet {
        margin: 2rem 0 0;
        padding: 0;
        width: 100%;
        height: 0.1rem;
        background-color: $grey-75;
        border: 0;
        position: relative;
        cursor: pointer;

        &:focus-visible {
            outline-offset: 0.4rem;
        }

        &::after {
            content: '';
            width: 0;
            height: 0.1rem;
            background-color: $black;
            display: block;
            transition: width 3000ms;
        }

        &--Active {
            &::after {
                animation: fill-animation 3000ms linear forwards;
            }
        }
    }

    &__Button {
        margin: 3rem 0 0;
        text-align: center;
    }
}
