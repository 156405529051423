@import 'styles/includes';

.ArticleImageText {
    $root: &;

    margin: 8rem 0;
    padding: 8rem 0;
    background-color: $black;
    color: $white;

    &__Container {
        @extend %container;
        display: grid;
        gap: 2.4rem;
        align-items: start;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
            gap: 4rem;
        }

        @include media(l) {
            grid-template-columns: auto 41.4rem;
            gap: 12.8rem;
        }
    }

    &__Image {
        position: relative;
        aspect-ratio: 738/560;
    }

    &__Content {
        font-size: 1.4rem;
    }

    &__Text {
        padding: 1rem 0 3rem;
        font-family: $font-family-regular;

        font-weight: $font-weight-regular;
        line-height: 100%;
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
    }

    &__Title {
        margin: 1rem 0 0;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        color: $grey-60;
        line-height: 100%;
        text-transform: none;
        display: block;
    }

    &__Name {
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        color: $white;
        line-height: 120%;
        text-transform: uppercase;
        display: block;
    }
}
