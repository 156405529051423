@import 'styles/includes';

.ImageLinkList {
    $root: &;

    margin: 6rem 0;

    @include media(m) {
        margin: 12rem 0;
    }

    &__Container {
        @extend %container;
    }

    &__Grid {
        display: grid;
        gap: 2rem;

        @include media(m) {
            grid-template-columns: repeat(3, 1fr);
            gap: 3rem;
        }
    }
}
