@import 'styles/includes';

.CustomerServiceAccordionList {
    $root: &;

    @extend %container;

    &__Search {
        margin: 4rem 0 0;
        position: relative;

        @include media(l) {
            margin: 8rem 0 0;
        }
    }

    &__Icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include media(l) {
            width: 30px;
            height: 30px;
        }
    }

    &__SearchField {
        padding: 2rem 13rem 2rem 2.5rem;
        width: 100%;
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        line-height: 90%;
        color: $black;
        border: 0;
        border-bottom: 1px solid $black;

        @include media(l) {
            padding: 4rem 24rem 4rem 5rem;
            font-size: 4rem;
        }

        &::placeholder {
            font-family: $font-family-bold;
            font-size: 2rem;
            font-weight: $font-weight-bold;
            line-height: 90%;
            color: $grey-60;

            @include media(l) {
                font-size: 4rem;
            }
        }
    }

    &__EmptySearch {
        padding: 0;
        width: 20px;
        height: 20px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;

        @include media(l) {
            width: 30px;
            height: 30px;
        }
    }

    &__Results {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 90%;
        color: $grey-60;
        position: absolute;
        top: 50%;
        right: 2.5rem;
        transform: translateY(-50%);

        @include media(l) {
            right: 4rem;
            font-size: 4rem;
        }
    }

    &__Grid {
        padding-top: 4rem;
        padding-bottom: 8rem;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @include media(l) {
            padding-top: 8rem;
            padding-bottom: 12rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            align-items: start;
            gap: 4rem;
        }

        &--Full {
            grid-template-columns: 1fr;
            gap: 0;
        }
    }
}
