@import 'styles/includes';

.FiftyFiftyCtaList {
    $root: &;
    padding: 6rem 0;
    background-color: $black;

    @include media(m) {
        padding: 12rem 0;
    }

    &__Grid {
        @extend %container;
        padding: 0 1rem;
        display: grid;
        gap: 2rem;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
