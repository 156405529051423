@import 'styles/includes';

.ArticleQuote {
    $root: &;

    padding: 8rem 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    color: $white;

    &__Container {
        @extend %container;
    }

    &__BlockQuote {
        margin: 0 auto;
        width: 100%;
        max-width: 88rem;
    }

    &__Text {
        font-family: $font-family-bold;
        font-size: 4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
    }

    &__Footer {
        margin-top: 14rem;
        font-family: $font-family-bold;
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
    }

    &__Image {
        margin: 0 auto;
        width: 6rem;
        height: 6rem;
        border-radius: 10rem;
        position: relative;
        overflow: hidden;
    }

    &__List {
        margin: 2.4rem 0 0;
    }
}
