@import 'styles/includes';

.HeroLandingPageParallax {
    $root: &;
    position: relative;
    overflow: hidden;
    background-color: $black;

    &::before {
        content: '';
        background: rgb(0, 0, 0);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0.4) 100%
        );
        position: absolute;
        inset: 0;
        z-index: 1;
    }

    &__Container {
        @extend %container;
        padding-bottom: 3rem;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        inset: 0;
        z-index: 1;
    }

    &__Image {
        height: 100vh;
        position: relative;
        will-change: transform;
    }

    &__Kicker {
        font-family: $font-family-regular;
        font-size: 2.4rem;
        font-weight: $font-weight-regular;
        line-height: 100%;
        color: $white;
    }

    &__Title {
        margin: 0 0 3rem;
        max-width: 102rem;
        font-family: $font-family-bold;
        font-size: 4.8rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.03rem;
        line-height: 100%;
        color: $white;

        @include media(s) {
            font-size: 7.2rem;
        }

        @include media(m) {
            font-size: 9.6rem;
        }
    }

    &__ScrollToContent {
        margin: 0 auto;
        padding: 1.5rem 0 0;
        border: 0;
        background-color: transparent;
        color: $white;

        &:hover {
            cursor: pointer;
        }
    }
}
