@import 'styles/includes';

.ContactCtaLarge {
    $root: &;
    background-color: $black;
    margin-top: 8rem;

    @include media(l) {
        margin-top: 12rem;
    }

    &__Container {
        @extend %container;
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: grid;
        grid-template-rows: 1fr;

        @include media(l) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }
    }

    &__Content {
        padding: 8rem 2rem;
        border: 1px solid $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media(s) {
            padding: 20rem 3rem;
        }
    }

    &__Title {
        max-width: 64rem;
        font-family: $font-family-bold;
        font-size: 2.6rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
        color: $white;

        @include media(s) {
            font-size: 4rem;
        }

        @include media(m) {
            font-size: 3.6rem;
        }

        &::after {
            content: '';
            margin: 2rem auto;
            width: 0.1rem;
            height: 10rem;
            background-color: $white;
            display: block;
        }
    }
}
