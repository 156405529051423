@import 'styles/includes';

.NewsHubControls {
    $root: &;

    display: flex;
    // flex-direction: column;
    gap: 1.2rem;

    @include media(s) {
        // flex-direction: row;
        justify-content: space-between;
    }

    &__Filters {
        display: flex;
        gap: 1.2rem;
        overflow-x: scroll;

        @include media(m) {
            margin: 0;
            overflow: visible;
        }
    }

    &__SelectContainer {
        padding-left: 10px;
        border-left: 1px solid $black;

        @include media(m) {
            padding-left: 0;
            border: 0;
        }
    }

    &__Select {
        padding: 0.6rem 2.8rem 0.6rem 1.2rem;
        font-family: $font-family-regular;
        font-weight: $font-weight-regular;
        font-size: 1.4rem;
        line-height: 100%;
        color: $black;
        background-image: url('/img/icon-chevron.svg');
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1rem 16px;
        background-color: transparent;
        border: 1px solid $black;
        border-radius: 0.4rem;
        align-self: flex-end;
        -webkit-appearance: none;
        -moz-appearance: none;

        @include media(m) {
            margin: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__Button {
        padding: 6px 12px;
        font-family: $font-family-regular;
        font-weight: $font-weight-regular;
        font-size: 1.4rem;
        line-height: 100%;
        color: $black;
        background-color: $white;
        border: 1px solid $black;
        border-radius: 0.4rem;
        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;

        &--Active {
            color: $white;
            background-color: $black;
        }

        @media (hover: hover) {
            &:hover {
                color: $white;
                background-color: $black;
                cursor: pointer;
            }
        }
    }

    &__Title,
    &__Occurrences {
        white-space: nowrap;
    }

    &__Title {
        margin-right: 1rem;
    }

    &__Occurrences {
        color: $grey-80;
        transition: color $transition-ease-out-expo;

        #{$root}__Button--Active & {
            color: $grey-50;
        }
        
        @media (hover: hover) {
            #{$root}__Button:hover & {
                color: $grey-50;
            }
        }
    }
}
