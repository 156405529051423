@import 'styles/includes';

.ArticleRelated {
    $root: &;

    @extend %container;
    padding-top: 8rem;
    padding-bottom: 8rem;

    &__Title {
        font-family: $font-family-bold;
        font-size: 4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
    }

    &__Header {
        margin: 0 0 4rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 2rem;
    }

    &__Navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    &__Button {
        padding: 0;
        border: 0;
        background-color: transparent;

        &:disabled {
            color: $grey-60;
        }
    }

    &__Swiper {
        border-left: 0.1rem solid $black;
    }

    &__Card {
        height: auto;
        border-top: 0.1rem solid $black;
        border-right: 0.1rem solid $black;
        border-bottom: 0.1rem solid $black;

        &:nth-child(1) {
            border-left: 0;
        }
    }

    &__LinkWrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__Link {
        margin: 4rem 0 0;
        font-family: $font-family-regular;
        font-size: 2.4rem;
        line-height: 100%;
        text-decoration: underline;
        text-decoration-thickness: 0.1rem;
        text-underline-offset: 0.4rem;
    }
}
