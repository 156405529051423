@import 'styles/includes';

.CustomerServiceAccordion {
    $root: &;

    &__Button {
        width: 100%;
        padding: 1rem 0 1rem;
        font-size: 2rem;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        line-height: normal;
        text-align: left;
        border: 0;
        border-bottom: 1px solid $black;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        @include media(m) {
            padding: 2rem 0 2rem;
            font-size: 4rem;
        }

        #{$root}__Accordion--Expanded & {
            border-bottom: 3px solid $black;

            @include media(m) {
                border-bottom: 4px solid $black;
            }
        }
    }

    &__Title {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__Subcategories {
        font-family: $font-family-regular;
        font-size: 1.2rem;
        font-weight: $font-weight-regular;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(m) {
            font-size: 2rem;
        }
    }

    &__Icon {
        width: 14px;
        flex-shrink: 0;

        @include media(m) {
            width: 20px;
        }

        #{$root}__Accordion--Expanded & {
            transform: rotate(180deg);
        }
    }

    &__Panel {
        display: grid;
        grid-template-rows: 0fr;
        // transition: grid-template-rows 150ms ease-out 0s;

        #{$root}__Accordion--Expanded & {
            grid-template-rows: 1fr;
        }
    }

    &__Content {
        overflow: hidden;
        visibility: hidden;

        #{$root}__Accordion--Expanded & {
            overflow: visible;
            visibility: visible;
        }
    }
}
