@import 'styles/includes';

.NewsCardLarge {
    $root: &;

    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $black;
    border-left: 0;
    border-right: 0;
    transition: border-color $transition-ease-out-expo;

    @include media(m) {
        flex-direction: row;
    }

    @include media(l) {
        border: 1px solid $black;
    }

    &--NoImage {
        min-height: 27rem;

        @include media(m) {
            min-height: 43rem;
        }
    }

    &__Link {
        @extend %card-link;
    }

    &__Image {
        flex: 1 1 50%;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include media(m) {
            aspect-ratio: 677/480;
        }
    }

    &__Text {
        padding: 2rem;
        background-color: $white;
        font-family: $font-family-regular;
        font-weight: $font-weight-regular;
        font-size: 1.4rem;
        line-height: 100%;
        color: $black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 50%;
        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;

        @include media(m) {
            padding: 4rem;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                background-color: $black;
                color: $white;
            }
        }
    }

    &__Content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

        @include media(m) {
            gap: 4rem;
        }
    }

    &__Date,
    &__Type {
        text-transform: uppercase;
    }

    &__Date,
    &__TopicTitle {
        color: $grey-80;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $grey-50;
            }
        }
    }

    &__Title {
        width: 100%;
        margin: 0 0 2rem;
        font-family: $font-family-bold;
        font-size: 3rem;
        line-clamp: 2;
        line-height: 120%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @include media(m) {
            margin: 0;
            font-size: 5rem;
        }
    }

    &__Bottom {
        width: 100%;
        padding: 0.5rem 0;
        display: flex;
        align-self: flex-end;
        justify-content: space-between;
    }

    &__TopicTitle {
        margin-bottom: 0.4rem;
    }

    &__TopicText {
        max-width: 30ch;
        overflow: hidden;
        line-height: 100%;
        text-overflow: ellipsis;
        text-transform: uppercase;
    }

    &__Arrow {
        margin-left: auto;
        align-self: flex-end;
    }
}
